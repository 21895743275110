import React, { useState ,useEffect } from "react";
import axios from "axios";
import { useParams ,useNavigate } from "react-router-dom";

const Update = () => {
 const navigate = useNavigate()
  const handleSubmit = async(e) => {
   e.preventDefault();
   await axios.put(`http://localhost:5000/product/${params.id}`, input );
   navigate('/')
  };
  const [input, setInput] = useState({
    name: "",
    price: "",
    category: "",
    company: "", 
    userID: "",
  });

  const params = useParams()


  const getUserData =async()=>{
   console.log(params);
   let wow = await axios.get(`http://localhost:5000/product/${params.id}`)
   console.log(wow.data); 
   let data = wow.data
   setInput({
    name: data.name, 
    price: data.price,
    category: data.category,
    company: data.company,
  })
  }
console.log(input.name);
  useEffect(() => {
   const storedUserID = JSON.parse(localStorage.getItem('user'))
   console.log( storedUserID._id );
   if (storedUserID) {
     setInput({ ...input, userID: storedUserID._id });
   }
   getUserData()

 }, []);

  return (
    <>
      <form className="flex flex-col justify-center items-center " onSubmit={handleSubmit} action="">
        <input
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
          value={input.name}
          name="name"
          required
          type="text"
          placeholder="Write Your Product Name"
        />
        <input
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
          value={input.price}
          name="price"
          type="text"
          required
          placeholder="Write Your Product Price"
        />
        <input
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
          value={input.category}
          name="category"
          type="text"
          required
          placeholder="Write Your Product Category"
        />
        <input
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
          value={input.company}
          name="company"
          type="text"
          required
          placeholder="Write Your Product Company"
        />
        <button type="submit" className="btn">Update </button>
      </form>
    </>
  );
};

export default Update;
