import React, { useState ,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const AddProduct = () => {
  const navigate = useNavigate()
  const handleSubmit = async(e) => {
   e.preventDefault();
   await axios.post("http://localhost:5000/add-product", input );
   navigate('/')
  };
  const [input, setInput] = useState({
    name: "",
    price: "",
    category: "",
    company: "", 
    userID: "",
  });

  const [error , setError] =useState(false)

  useEffect(() => {
   const storedUserID = JSON.parse(localStorage.getItem('user'))
   console.log( storedUserID._id );
   if (storedUserID) {
     setInput({ ...input, userID: storedUserID._id });
   }
 }, []);

  return (
    <>
      <form className="flex flex-col justify-center items-center " onSubmit={handleSubmit} action="">
        <input
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
          value={input.name}
          name="name"
          required
          type="text"
          placeholder="Write Your Product Name"
        />
        <input
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
          value={input.price}
          name="price"
          type="text"
          required
          placeholder="Write Your Product Price"
        />
        <input
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
          value={input.category}
          name="category"
          type="text"
          required
          placeholder="Write Your Product Category"
        />
        <input
          onChange={(e) =>
            setInput({ ...input, [e.target.name]: e.target.value })
          }
          value={input.company}
          name="company"
          type="text"
          required
          placeholder="Write Your Product Company"
        />
        <button type="submit" className="btn">Submit</button>
      </form>
    </>
  );
};

export default AddProduct;
