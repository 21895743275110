import React, { useEffect, useState } from 'react';
import { useNavigate  , Link} from 'react-router-dom';
const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate()
  const collectData = async (e) => {
    console.log(email, name, password);
 

    try {
      const response = await fetch('http://localhost:5000/register', {
        method: 'POST', 
        body: JSON.stringify({ name, email, password }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
    localStorage.setItem('user' , JSON.stringify(result.sanitizedResult))
    localStorage.setItem('token' , JSON.stringify(result.auth))
    navigate('/profile')
      console.warn(result);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    collectData();
    
    
    

  };
useEffect(()=>{



 let auth = localStorage.getItem('user')
 if(auth){
  navigate('/')
 }
})


  
  return (
    <>
      <h1 className='text-center w-full text-3xl gap-5 p-5 shadow-sm'>Register yourself now</h1>

      <form onSubmit={handleSubmit}>
        <div className=''>
          <label className='p-2' htmlFor='name'>
            Name:
          </label>
          <input name='name' type='text' value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className=''>
          <label className='p-2' htmlFor='name'>
            Email:
          </label>
          <input name='email' type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className=''>
          <label className='formN' htmlFor='name'>
            Password:
          </label>
          <input
            name='password'
            type='password' // Use type 'password' for password input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button type='submit' className='text-center btn'>
          SignUp
        </button>
      </form>
    </>
  );
};

export default SignUp;
