import React from 'react';
import Nav from './components/Nav.jsx';
import Footer from './components/Footer.jsx';
import SignUp from './Pages/signUp.jsx'; 
import PrivateComponents from './components/PrivateComponents.js';
import Products from './Pages/Products.jsx';
import AddProduct from './components/AddProduct.jsx';
import Update from './Pages/Update.jsx';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Profile from './Pages/Profile.jsx';
import Login from './Pages/Login.jsx';

const App = () => {
  const auth =localStorage.getItem('auth')
  return (
    <>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path='/signup' element={<SignUp/>} /> 
          <Route path='/login' element={<Login/>} /> 
          <Route path='/update/:id' element={<Update/>} /> 
          <Route path='/add' element={<AddProduct/>} /> 
          <Route element={<PrivateComponents/>}>
         
          <Route path='/' element={<Products/>} /> 
          <Route path='/profile' element={<Profile/>} /> 
          </Route>
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
