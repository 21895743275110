
import React, { useEffect, useState } from 'react';
import { useNavigate  , Link} from 'react-router-dom';
const Login = () => {
 
 const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
 const navigate = useNavigate()
 const handleSubmit = async (e) => {
  e.preventDefault();
  
  try {
    const response = await fetch('http://localhost:5000/login', {
      method: 'POST',
      body: JSON.stringify({  email, password }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
     const result = await response.json();
     // Save all user data in local storage
     localStorage.setItem('user', JSON.stringify(result.user));
     localStorage.setItem('token', JSON.stringify(result.auth));
     navigate('/profile');

    } else {
      // Handle login failure, display an error message or redirect as needed
      console.error('Login failed');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

 
useEffect(()=>{



let auth = localStorage.getItem('user')
if(auth){
 navigate('/')
}
})


 
 return (
   <>
     <h1 className='text-center w-full text-3xl gap-5 p-5 shadow-sm'>Register yourself now</h1>

     <form onSubmit={handleSubmit}>
      
       <div className=''>
         <label className='border border-black'  htmlFor='name'>
           Email:
         </label>
         <input name='email' type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
       </div>
       <div className=''>
         <label className='w-24' htmlFor='name'>
           Password:
         </label>
         <input
           name='password'
           type='password' // Use type 'password' for password input
           value={password}
           onChange={(e) => setPassword(e.target.value)}
         />
       </div>

       <button type='submit' className='text-center btn'>
         Login
       </button>
     </form>
   </>
 );
};
export default Login