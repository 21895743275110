import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Products = () => {
  const [products, setProducts] = useState([]);

  const getMyData = async () => {
    try {
      const response = await axios.get('http://localhost:5000/product' ,{  headers: {
        authorization: `bearer ${JSON.parse(localStorage.getItem('token'))}` // Include the token in the headers
      }})
    
      setProducts(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleDel =(id)=>{
  let dataDel=  axios.delete(`http://localhost:5000/product/${id}`)
    if(dataDel){
    getMyData();
    }
    else{
      console.log('deleting data failed');
    }
  }



  useEffect(() => {
    getMyData();
  }, []);

  const searchData = async(event)=>{
    let key = event.target.value
 if(key){ 
  let getData = await axios.get(`http://localhost:5000/search/${key}`,{  headers: {
    authorization: `bearer ${JSON.parse(localStorage.getItem('token'))}`}} )
  setProducts(getData.data)
  console.log(getData.data); 
 }else{
  getMyData()
 }
  }

  console.warn(products);

  return (
    <>
<div className='flex align-center justify-center   ' >
<input className='' onChange={searchData} placeholder='search data'  type="search" />

</div>
      <table className="min-w-full border-collapse border border-gray-300">
        <thead>
          <tr className=' '> 
            <th className="py-2 px-4 bg-gray-100 border">Serial No</th>
            <th className="py-2 px-4 bg-gray-100 border">Name</th>
            <th className="py-2 px-4 bg-gray-100 border">Company</th>
            <th className="py-2 px-4 bg-gray-100 border">Category</th>
            <th className="py-2 px-4 bg-gray-100 border">Price</th>
            <th className="py-2 px-4 bg-gray-100 border">Actions</th>
            <th className="py-2 px-4 bg-gray-100 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          { products.length>0? products.map((item, index) => (
            <tr key={item._id}>
              <td className="py-2 text-center px-4  border">{index+1}</td>
              <td className="py-2 text-center px-4  border">{item.name}</td>
              <td className="py-2 text-center px-4 border">{item.company}</td>
              <td className="py-2 text-center px-4 border">{item.category}</td>
              <td className="py-2 text-center px-4 border">{item.price}</td>
              <td className="py-2 text-center px-4 border">
                <button onClick={()=>handleDel(item._id)} className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded">Delete</button>
              </td>
              <td className="py-2 text-center px-4 border">
                   <Link to={`/update/${item._id}`} className="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded">Update</Link>
              </td>
            </tr>

          ))
        : <h1 className='text-center text-2xl p-4 '>No data found</h1>}
        </tbody>
      </table>
    </>
  );
};

export default Products;
