import { Link, useNavigate } from 'react-router-dom';

const Nav = () => {
  const navigate = useNavigate();
  const auth = localStorage.getItem('user');
  const logout = () => {
    localStorage.clear();
    navigate('/signup');
  };

  return (
    <>
      <div className='bg-slate-700 px-5 text-white items-center flex justify-between'>
        <h1 className='text-2xl'>E-Commerce Dashboard</h1>

        {auth ? (
          <ul className='flex text-xl gap-6 text-white p-2 leading-[44px]'>
            <li>
              <Link to={'/'}>Products</Link>
            </li>
            <li>
              <Link to={'/add'}>Add Products</Link>
            </li>
            <li>
              <Link to={'/update'}>Update Products</Link>
            </li>

            <li>
              <Link to={'/profile'}>Profile</Link>
            </li>
            <li>
              <Link to={'/logout'} onClick={logout}>
                Logout({JSON.parse(auth).name})
              </Link>
            </li>
          </ul>
        ) : (
          <ul className='flex text-xl gap-6 text-white p-2 leading-[44px]'>
            <li>
              <Link className='pr-4' to={'/signup'}>
                Sign Up
              </Link>
            </li>
            <li>
              <Link to={'/login'}>Login</Link>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Nav;
